import React, { useState } from "react";
import styled from 'styled-components'
import Header from "../../components/Header";

const SignBackInContainer = styled.div`
  padding-bottom: 100px;
  margin-bottom: -100px;  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ecebff;
`;

const SignBackInButton = styled.div`
  border-radius: 100px;
  color: rgba(30, 23, 63, 0.6);
  background-color: rgba(30, 23, 63, 0.05);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;

  &:hover {
    cursor: pointer;
    background-color: #1e173f;
    color: #ecebff;
  }
`;

function NeedConfirmation({firebase}) {
  const [resent, setResent] = useState(false)
  return (
    <>
      <Header
        title="Nearly there 😃"
        subheading="Check your university email's inbox and click the link to verify your account 📭"
      />
      <SignBackInContainer>
        {resent ? 'Email sent, please check your inbox again (including spam!)' : <SignBackInButton onClick={() => firebase.auth.currentUser.sendEmailVerification().then(setResent(true))}>
        Resend email
        </SignBackInButton>}
        <br />
      </SignBackInContainer>
    </>
  );
}

export default NeedConfirmation;
