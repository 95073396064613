import React from "react";
import Gif from "../../images/Congratulations.gif";

function Success({ link, referrer }) {
  return (
    <>
      <div class="page-header wf-section">
        <h1 class="h1-title">Welcome to OSCEhub 🎉</h1>
        <p class="subheading-text">
          Your 7 day trial has begun. We hope you enjoy using OSCEhub!
        </p>
        <img src={Gif} loading="lazy" alt="" class="image-3" />
        {referrer && (
          <>
            <div class="subheading-text">You were referred by {referrer} and can now get 20% off all plans!</div>
          </>
        )}
      </div>
      <div class="section-browse wf-section">
        <div class="confirmation-page w-container">
          <h2 class="heading-3">
            Get unlimited <span class="text-span-10">FREE</span> months
          </h2>
          <p class="subheading-text">
            Get a free month every time someone signs up to OSCEhub using your
            personal referral link (they also get 20% off)!
          </p>
          <div class="referral-div">
            <div class="text-block-6 referral-link">{link}</div>
            <a
              class="button-copy-link w-button copy-link"
              onClick={() => navigator.clipboard.writeText(link)}
            >
              Copy Link
            </a>
          </div>
          <a href="/dashboard" class="button dashboard w-button">
            Go to Dashboard
          </a>
        </div>
      </div>
    </>
  );
}

export default Success;
