import React, { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../components/Firebase";
import NeedConfirmation from "../containers/Authentication/NeedConfirmation";
import Success from "../containers/Authentication/Success";
import ThickLoadingWall from "../components/ThickLoadingWall";
import automatedRedirects from "../helpers/automatedRedirects";

const RegistrationSuccess = () => {
  const { user, firebase, loading } = useContext(FirebaseContext);
  const [link, setLink] = useState("");
  const [referrer, setReferrer] = useState(null);
  useEffect(() => {
    if (!loading) {
      automatedRedirects(user);
      if (user) {
        setLink(user.referralLink);
      }
      if (user.emailVerified) {
        // if there is an affiliate for this current browser session
        if (window.Rewardful && window.Rewardful.affiliate) {
          const affiliate = window.Rewardful.affiliate;
          const affiliateName = `${affiliate.first_name} ${affiliate.last_name}`;
          // the processReferral function will check if currentUser has already been referred before this, if not, then process the referral. If it has, nothing will happen.
          firebase.processReferral({
            referrerId: affiliate.id,
            referrerName: affiliateName,
          });

          // in case the user has already been referred before, and is just trying to game the system, still acknowledge their referrer by getting the referrer from the current local 'user' profile
          if (user.referredBy) {
            setReferrer(user.referredBy);
          } else {
            setReferrer(affiliateName);
          }
        } else {
          // if there are no affiliates

          // if user has already been assigned organic
          if (user.referredBy == "organic") {
            return;
          } else { // if no assigned value, generate new organic value
            firebase.db
              .collection("zapier")
              .doc("new_users")
              .collection("organic")
              .doc(user.uid)
              .set(
                {
                  email: user.email,
                  firstname: user.firstname,
                  lastname: user.lastname,
                },
                { merge: true }
              );
            firebase.db
              .collection("users")
              .doc(user.uid)
              .set({ referred_by: "organic" }, { merge: true });
          }
        }
      }
    }
  }, [loading]);

  return (
    <div>
      {loading && <ThickLoadingWall />}
      <Success link={link} referrer={referrer} />
    </div>
  );
};

export default RegistrationSuccess;
